import React from "react"
import { Page } from "../components/layout"
import { UniversalLink } from "../components/UniversalLink"
import "./terms.scss"

export const Terms = () => (
  <Page
    header="Terms and Conditions"
    title="Developer Program Terms and Conditions"
    keywords="xrpl acclerator terms, xrpl acclerator terms and conditions, xrpl acclerator legal terms, xrpl code of conduct"
    description="Discover the Terms and Conditions of the XRP Ledger Developer Program which includes requirements and benefits for Developer Program participants."
  >
    <div className="prose">
      <p>
        These XRP Ledger Developer Program Terms and Conditions (“Terms”) apply
        to your participation in the XRP Ledger Developer Program (the
        “Developer Program”) offered by Ripple Labs Inc. and its subsidiaries
        and affiliated companies (“Ripple”, “we” or “us”, and together with you,
        the “Parties”; each, a “Party”), and describes the Developer Program
        requirements as well as benefits you may receive as a participant in the
        Developer Program. These Terms do not alter the terms or conditions of
        any other agreement you may have with Ripple for products, services, or
        otherwise (collectively, “Agreement”). To the extent there is a conflict
        between any Agreement and these Terms, the terms of the Agreement shall
        govern.
      </p>

      <p>
        Ripple reserves the right to change or modify these Terms at any time in
        its sole discretion and without prior specific notice to you. Your
        continued participation in the Developer Program will indicate your
        acceptance of such changed or modified Terms.
      </p>

      <p>
        While all Project Proposals (defined below) submitted by you (as an
        applicant or after acceptance into the Developer Program by Ripple) may
        be reviewed by a panel of judges chosen by Ripple in its sole discretion
        (the “Program Committee”), your creation or release of Software (defined
        below) or your engagement in any other Developer Program-related
        activities, do not obligate Ripple to provide you with Developer Fee
        (defined below) or any other Developer Program benefits. YOUR
        PARTICIPATION IN THE DEVELOPER PROGRAM IS ENTIRELY VOLUNTARY, BUT IF YOU
        ARE PARTICIPATING IN THE PROGRAM, YOU MUST STRICTLY ADHERE TO THESE
        TERMS. IF, AS PART OF YOUR VOLUNTARY PARTICIPATION, YOU MEET THE
        CRITERIA LISTED BELOW, YOU MAY RECEIVE CERTAIN AMOUNTS OF XRP OR FIAT
        CURRENCY (“DEVELOPER FEES”).
      </p>

      <p>
        Ripple reserves the right, in its sole discretion, to modify, suspend,
        or cancel the Accelerator Program without prior notice to you.
      </p>

      <ol>
        <li>
          DEFINED TERMS
          <p>
            “Intellectual Property Rights” means patent rights (including patent
            applications and disclosures), copyrights, trademarks, trade secret
            rights, and any other intangible property rights recognized in any
            country or jurisdiction in the world. “Participant Marks” means the
            trademarks, service marks, trade names and logos for your products
            and services.
          </p>
          <p>
            “Participant Materials” means any Project Proposal, Software, and
            any other materials or information provided by you (including your
            team members if applicable) to Ripple or the Program Committee in
            connection with the DeveloperProgram.
          </p>
          <p>
            “Project Proposal” means a general description of each proposed
            Software, which among other details, specifies the features of the
            applicable Software, summarizes the anticipated benefits and
            improvements to the XRP Ledger and the XRP ecosystem, details on
            your team, and information on the budget. “Program Milestone” means
            the program milestones agreed upon between you and Ripple
          </p>
          <p>
            “Ripple Materials” means all information and materials that may be
            made available to you by Ripple in the course of the Accelerator
            Program and all Intellectual Property Rights thereto.
          </p>
          <p>
            “Developer Fee” means the eligible amount of XRP or fiat currency
            that may be paid to you in connection with the Developer Program for
            the completion of a Program Milestone.
          </p>
          <p>
            “Software” means the software, code, or other materials and content
            that you submit (or desire to submit) to Ripple for review pand/or
            consideration by Ripple for the Accelerator Program under these
            Terms.
          </p>
          <p>“XRP” means the digital asset native to the XRP Ledger.</p>
          <p>
            “XRP Ledger” means the distributed ledger on which XRP is the native
            digital asset.
          </p>
        </li>
        <li>
          AWARDEE PARTICIPATION AND CONDUCT
          <ol>
            <li>
              Code of conduct. You agree to follow the Contributor Covenant Code
              of Conduct set forth on{` `}
              <UniversalLink to="https://github.com/ripple/xrpl-dev-portal/blob/master/CODE_OF_CONDUCT.md">
                https://github.com/ripple/xrpl-dev-portal/blob/master/CODE_OF_CONDUCT.md
              </UniversalLink>
            </li>
            <li>
              As an XRPL Accelerator participant you are expected to to maintain
              a professional, collegial, and constructive environment with other
              builders.
            </li>
            <li>
              Ripple reserves the right to withhold or rescind any given awards,
              including the dissolution of any agreement between Ripple and the
              participant for any violations of the Code of Conduct, or any
              other behavior deemed by Ripple to be harmful to the ecosystem.
            </li>
          </ol>
        </li>
        <li>
          PROGRAM REQUIREMENTS
          <ol>
            <li>
              Eligibility. By submitting an application, you represent and
              warrant that each of the following statements is true and
              accurate, and all of the information you provided was and shall
              remain true and complete;
              <ol>
                <li>
                  If you are registering on behalf of a legal entity, such legal
                  entity is duly organized and validly existing under the
                  applicable laws of the jurisdiction of its organization and
                  you are duly authorized by such legal entity to act on its
                  behalf;
                </li>
                <li>
                  You are of legal age to form a binding contract in the
                  jurisdiction in which you are located, and you are over 18
                  years old;
                </li>
                <li>
                  You have the right, full power, and authority to enter into
                  these Terms, to exercise your rights and perform your
                  obligations under these Terms, and in so doing, will not
                  violate any other agreement to which you are a party nor any
                  laws applicable to you;
                </li>
                <li>
                  These Terms constitute legal, valid, and binding obligations
                  on you that are enforceable against you in accordance with
                  their terms;
                </li>
                <li>
                  You, in good faith, have legal right or are otherwise
                  authorized to any submission you make to the Developer
                  Program.
                </li>
                <li>
                  No consent, authorization, license, or approval of, or notice
                  to, any governmental authority, nor your shareholders,
                  partners, members, other record or beneficial owners, or other
                  any relevant person (as applicable) is required to authorize
                  the execution, delivery, validity, enforceability, or
                  admissibility in evidence of the performance by you of your
                  obligations under these Terms; &ldquo; &rdquo;
                </li>
                <li>
                  You are not:
                  <ol>
                    <li>
                      listed on any of the following lists (each a
                      &ldquo;Sanctions List&rdquo;): the Consolidated United
                      Nations Security Council Sanctions List; the Specially
                      Designated Nationals and Blocked Persons List or the
                      Sectoral Sanctions Identification List maintained by the
                      US Office of Foreign Assets Control (&ldquo;OFAC&rdquo;);
                      the Consolidated List of Persons, Groups and Entities
                      subject to EU Financial Sanctions; the Consolidated List
                      of Financial Sanctions Targets or List of persons subject
                      to restrictive measures in view of Russia&&lsquo;s actions
                      destabilizing the situation in Ukraine, maintained by the
                      UK Treasury; the Overall List of Sanctioned Individuals,
                      Entities and Organizations maintained by the Swiss State
                      Secretariat for Economic Affairs (&ldquo;SECO&rdquo;);
                      &lsquo;Ordinance lists of the Swiss Federal
                      Council&rsquo;; or any similar list maintained by, or
                      public announcement of sanctions made by, any other
                      Sanctions Authority (as defined below);
                    </li>
                    <li>
                      owned or controlled by, or acting on behalf of or for the
                      benefit of, any person on a Sanctions List;
                    </li>
                    <li>
                      a citizen of, or resident in, or located in, or
                      incorporated in Syria, Iran, Cuba, North Korea or the
                      Crimea, Donetsk People’s Republic, or Luhansk People’s
                      Republic regions of Ukraine, or any other country or
                      territory which, after the Effective Date, becomes the
                      target of such comprehensive, country-wide or
                      territory-wide Sanctions (as defined below) as currently
                      apply to the aforementioned territories; or
                    </li>
                    <li>
                      the target of any sanctions laws, regulations, embargoes
                      or restrictive measures (“Sanctions”), as amended from
                      time to time, administered, enacted or enforced by: the
                      United Nations, the United States, the European Union or
                      any Member State thereof, the United Kingdom, Switzerland
                      or the respective Governmental Authorities and agencies of
                      any of the foregoing responsible for administering,
                      enacting or enforcing Sanctions, including without
                      limitation, OFAC, the US Department of State, the United
                      Kingdom Treasury or the SECO (each, a “Sanctions
                      Authority”).
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              Additional Requirements. You acknowledge and agree that to
              participate in the Developer Program, you must execute any
              additional terms or forms (e.g., IRS Form W-9, IRS Form series
              W-8, other applicable tax resident certification, or sanctions
              screening questionnaire), as may be requested of you, and must
              comply with all Developer Program requirements communicated to
              you, in each case by Ripple and/or the Program Committee.
              Developer Fees are granted without warranty of any kind from
              Ripple, express or implied, without limitation. All applicable
              federal, state and local laws and regulations apply. You agree to
              be bound by the decisions of Ripple, which are final and binding
              on all matters pertaining to the Developer Program.
            </li>
          </ol>
        </li>
        <li>
          Project Milestones. Ripple shall condition providing any Developer Fee
          or portion of a Developer Fee on your completion of the applicable
          Project Milestones to Ripple’s satisfaction (in Ripple sole
          discretion).
        </li>
        <li>
          TERMS OF GRANT GIVING.
          <p>
            Ripple may pay you a Developer Fee as communicated to you by Ripple
            during the Developer Program process. You agree that no additional
            compensation is due for the actual development costs incurred. The
            Developer Fee shall be paid as milestone payments for the
            accomplishment of one or more Project Milestones, as may be
            indicated to you by the Program Committee.
          </p>
          <p>
            You shall not be entitled to an increase in compensation, even if
            you have had more work or greater expenses than anticipated. An
            increase in compensation is also excluded if extraordinary
            circumstances which could not have been foreseen prevent the
            completion of the Software or make it excessively difficult.
          </p>
          <p>
            DEVELOPER FEE: You may not use the Developer Fee for any purpose
            other than indicated during the Development Program process or as
            otherwise agreed by the Program Committee and/or Ripple. The
            Developer Fee in its entirety must be used for software development
            on the XRP Ledger. At Ripple’s request, you will repay any unused
            portion of the Developer Fee or any portion of the Developer Fee
            used or committed for use while you are in material breach of this
            Agreement, as determined by Ripple in its sole discretion.
          </p>
          <p>
            THE PAYMENT OF ANY DEVELOPER FEE TO YOU IS SUBJECT TO COMPLIANCE
            WITH APPLICABLE LAWS, AS DETERMINED BY RIPPLE IN ITS SOLE
            DISCRETION. In receiving and using the Developer Fee, you agree to
            comply with all applicable laws, regulations, and rules and will not
            infringe, misappropriate, or violate the intellectual property,
            privacy, or publicity rights of any third party. You also agree to
            use the Developer Fee in compliance with all international trade
            laws, i.e., any law, statute, code, or order relating to
            international trade, including, but not limited to: (i) all import
            laws and regulations, including but not limited to those
            administered by U.S. Customs and Border Protection, (ii) export
            control regulations, including but not limited to laws and
            regulations issued by the U.S. Department of State pursuant to the
            International Traffic in Arms Regulations (22 C.F.R. 120 et seq.)
            and/or the U.S. Department of Commerce pursuant to the Export
            Administration Regulations (15 C.F.R. 730 et seq.); (iii) sanctions
            laws and regulations as administered by the U.S. Department of the
            Treasury’s Office of Foreign Assets Control (31 C.F.R. Part 500 et
            seq.); (iv) U.S. anti-boycott laws and requirements (Section 999 of
            the US Internal Revenue Code of 1986, as amended, or related
            provisions, or under the Export Administration Act, as amended, 50
            U.S.C. App. Section 2407 et. seq.); (v) any other similar law,
            directive, or regulation (including those of the European Union or
            any of its Member States) related to similar subject matter; (vi)
            applicable anti-money laundering laws, regulations, rules, or
            guidelines in United States and in your jurisdiction of
            incorporation; and (vii) anti-bribery and corruption laws including
            but not limited to the Foreign Corrupt Practices Act. Further, you
            will not take any action that could cause Ripple to violate its
            obligations under any such international trade laws.
          </p>
        </li>
        <li>
          Taxes and other duties. You shall bear all applicable taxes, duties,
          deductions, imposts, or governmental levies, including withholding
          taxes, digital services taxes, transfer taxes, sales or use taxes,
          value-added taxes, consumption taxes, stamp taxes, excise taxes, or
          other similar taxes (collectively “Taxes”) arising under the Developer
          Fee. Ripple shall have no obligation to indemnify, gross-up, or
          otherwise reimburse you for applicable Taxes. If and to the extent
          applicable law obligates Ripple to deduct Taxes from any Developer Fee
          paid to you including from the transfer of XRP, Ripple shall timely
          deduct and remit such Taxes to the applicable tax authority and shall
          furnish you with an official receipt or other appropriate evidence of
          such remittance. If requested by Ripple, you shall promptly provide
          Ripple with a valid IRS W-9 form, valid IRS series W-8 form, or other
          similar tax residency certification as necessary and appropriate.
          Without limiting the foregoing, Ripple and you acknowledge and agree
          that net income taxes, franchise taxes, branch profit taxes, or
          similar income taxes imposed on a Party as a result of such Party
          being organized under the laws of, having its principal office,
          management and control, or employees located in, conducting business
          within, or by reason of another connection between Party and the
          jurisdiction imposing such tax, shall be the sole responsibility of
          such Party.
        </li>
        <li>
          Marketing and Promotion.
          <ol>
            <li>
              Publicity. You will not issue any press releases, social media
              post, or similar public announcements in connection with these
              Terms (including statements regarding the terms and conditions of
              these Terms and the details of the Developer Program), without the
              prior written approval of Ripple.
            </li>
            <li>
              Publication in List. You agree that Ripple may, but is not
              required, to include the Software in published lists and similar
              compilations and on its online properties during the term of the
              Terms, including on webpages and other promotional materials
              regarding third party products. You agree to provide Ripple with
              product descriptions, images, logos, website links and other
              content regarding the Software as may be reasonably requested by
              Ripple for inclusion in such lists and compilations, and for use
              in its related marketing activities.
            </li>
            <li>
              Code of conduct. You agree to follow the Contributor Covenant Code
              of Conduct set forth on{` `}
              <UniversalLink to="https://github.com/ripple/xrpl-dev-portal/blob/master/CODE_OF_CONDUCT.md">
                https://github.com/ripple/xrpl-dev-portal/blob/master/CODE_OF_CONDUCT.md
              </UniversalLink>
            </li>
          </ol>
        </li>
        <li>
          Required Disclosures. To the extent that any Developer Fee is
          denominated in XRP:
          <ol>
            <li>
              You acknowledge and agree that you cannot cancel, reverse, or
              change any XRP transaction that has been completed, as recorded on
              the XRP Ledger.
            </li>
            <li>
              You acknowledge and agree that you are solely responsible for
              maintaining adequate security and control of any and all IDs,
              passwords, hints, personal identification numbers (PINs), secret
              keys or any other codes that you use to access the Developer Fee.
              Any loss or compromise of your credentials may result in
              unauthorized access to the Developer Fee by third-parties and the
              loss or theft of such XRP. Ripple assumes no responsibility for
              any loss that you may sustain due to compromise of your
              credentials.
            </li>
            <li>
              Ripple makes no representations or warranties with respect to the
              regulatory classification of XRP in any jurisdiction, including
              the United States and you agree that you are not relying on any
              such representations or warranties in entering into these terms or
              participating in the Developer Program. In addition, you
              acknowledge the following material risks associated with virtual
              currency, including XRP:
              <ol>
                <li>
                  Virtual currency is not legal tender, is not backed by the
                  government, and accounts and value balances are not subject to
                  Federal Deposit Insurance Corporation or Securities Investor
                  Protection Corporation protections.
                </li>
                <li>
                  Legislative and regulatory changes or litigation or actions at
                  the state, federal, or international level may adversely
                  affect the use, transfer, exchange, and value of virtual
                  currency, including XRP.
                </li>
                <li>
                  Transactions in virtual currency may be irreversible and,
                  accordingly, losses due to fraudulent or accidental
                  transactions may not be recoverable.
                </li>
                <li>
                  Some virtual currency transactions shall be deemed to be made
                  when recorded on a public ledger, which is not necessarily the
                  date or time that the transaction is initiated.
                </li>
                <li>
                  The value of virtual currency may be derived from the
                  continued willingness of market participants to exchange fiat
                  currency for virtual currency, which may result in the
                  potential for permanent and total loss of value of a
                  particular virtual currency should the market for that virtual
                  currency disappear.
                </li>
                <li>
                  There is no assurance that a person who accepts a virtual
                  currency as payment today will continue to do so in the
                  future.
                </li>
                <li>
                  The volatility and unpredictability of the price of virtual
                  currency relative to fiat currency may result in significant
                  loss over a short period of time.
                </li>
                <li>
                  The nature of virtual currency may lead to an increased risk
                  of fraud or cyber attack.
                </li>
                <li>
                  The nature of virtual currency means that any technological
                  difficulties experienced by Ripple may prevent the access or
                  use of your virtual currency.
                </li>
                <li>
                  Any bond or trust account maintained by Ripple for the benefit
                  of XRP holders may not be sufficient to cover all losses
                  incurred by you.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          Intellectual Property.
          <ol>
            <li>
              Participant Materials. You hereby grant to Ripple a non-exclusive,
              irrevocable, perpetual, worldwide, royalty-free, sublicensable
              right and license to use, reproduce, modify, distribute, display,
              publish, perform, transmit and access your Participant Materials
              in connection with the Developer Program, in any media formats,
              through any media channels or otherwise. You acknowledge that
              Ripple may currently be developing or in the future develop
              information internally, or may have received or in the future
              receive information from other parties that is similar to your
              Participant Materials. Nothing in these Terms will be construed as
              a representation or agreement that Ripple will not develop or have
              developed products, concepts, systems, or techniques that are
              similar to or compete with the products, concepts, systems, or
              techniques contemplated by or embodied in any Participant
              Materials.
            </li>
            <li>
              Participant Marks. You grant to Ripple a non-exclusive,
              irrevocable, worldwide, non-sublicensable, royalty-free license to
              use your Participant Marks solely in connection with the Developer
              Program, in any media formats, including, but not limited to
              advertising, marketing, and promoting Ripple and Ripple’s products
              and services and identification of and discussion about your
              participation in the Developer Program. All goodwill arising from
              use of the Participant Marks will inure solely to the benefit of
              you.
            </li>
            <li>
              Ownership. As between you and Ripple, (a) Ripple owns all right,
              title and interest in and to the Ripple Materials and Ripple’s
              products and services, including all associated Intellectual
              Property Rights, and (b) you own all right, title and interest in
              and to your Participant Marks, including all associated
              Intellectual Property Rights.
            </li>
          </ol>
        </li>
        <li>
          Indemnification. You agree to defend, indemnify and hold harmless
          Ripple, its independent contractors, service providers and
          consultants, and their respective directors, employees and agents,
          from and against any claims, damages, costs, liabilities and expenses
          (including, but not limited to, reasonable attorneys’ fees) arising
          out of or related to: (a) your use of the Developer Program Site; (b)
          your participation in the Developer Program; (c) your violation of
          these Terms; (d) your violation of any rights of another; or (e) your
          conduct in connection with the Developer Program. Some jurisdictions
          limit consumer indemnities, so some or all of the indemnity provisions
          above may not apply to you. If you are obligated to indemnify us, we
          will have the right, in our sole and unfettered discretion, to control
          any action or proceeding and determine whether we wish to settle it,
          and if so, on what terms.
        </li>
        <li>
          Limitation of Liability. IN NO EVENT WILL RIPPLE, ITS DIRECTORS,
          EMPLOYEES OR AGENTS BE LIABLE FOR ANY DIRECT, SPECIAL, INDIRECT OR
          CONSEQUENTIAL DAMAGES, OR ANY OTHER DAMAGES OF ANY KIND, INCLUDING BUT
          NOT LIMITED TO LOSS OF USE, LOSS OF PROFITS OR LOSS OF DATA, WHETHER
          IN AN ACTION IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO
          NEGLIGENCE) OR OTHERWISE, ARISING OUT OF OR IN ANY WAY CONNECTED WITH
          THESE TERMS, THE GRANT PROGRAM, THE RIPPLE MATERIALS, OR THE CONTENT
          OR THE MATERIALS CONTAINED IN OR ACCESSED THROUGH THE GRANT PROGRAM
          SITE OR IN CONNECTION WITH THE DEVELOPER PROGRAM, INCLUDING WITHOUT
          LIMITATION ANY DAMAGES CAUSED BY OR RESULTING FROM RELIANCE BY USER ON
          ANY INFORMATION OBTAINED FROM RIPPLE, OR THAT RESULT FROM MISTAKES,
          OMISSIONS, INTERRUPTIONS, DELETION OF FILES OR EMAIL, ERRORS, DEFECTS,
          VIRUSES, DELAYS IN OPERATION OR TRANSMISSION OR ANY FAILURE OF
          PERFORMANCE, WHETHER OR NOT RESULTING FROM ACTS OF GOD, COMMUNICATIONS
          FAILURE, THEFT, DESTRUCTION OR UNAUTHORIZED ACCESS TO RIPPLE’S
          RECORDS, PROGRAMS OR SITE. IN NO EVENT WILL THE AGGREGATE LIABILITY OF
          RIPPLE, WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE,
          WHETHER ACTIVE, PASSIVE OR IMPUTED), PRODUCT LIABILITY, STRICT
          LIABILITY OR OTHER THEORY, ARISING OUT OF OR RELATING TO THESE TERMS,
          THE GRANT PROGRAM, THE RIPPLE MATERIALS, OR THE CONTENT OR THE
          MATERIALS CONTAINED IN OR ACCESSED THROUGH THE GRANT PROGRAM SITE OR
          IN CONNECTION WITH THE GRANT PROGRAM, EXCEED ONE THOUSAND U.S. DOLLARS
          (US$1,000). SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF
          LIABILITY IN CONTRACTS WITH CONSUMERS, SO SOME OR ALL OF THESE
          LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU
        </li>
        <li>
          Termination. The term of these Terms commences from the Effective Date
          and continues until (i) the completion of Participant’s participation
          in the Developer Program or (ii) these Terms or Developer Program are
          terminated by Ripple, in its sole discretion, whichever is earlier
          (the “Term”). For the avoidance of doubt, Ripple may terminate these
          Terms for convenience at any time. Upon any termination,
          discontinuation, or cancellation of the Developer Program or these
          Terms, all provisions of these Terms which by their nature should
          survive will survive, including, without limitation, provisions
          regarding ownership, warranty disclaimers, limitations of liability,
          indemnification obligations, and dispute resolution.
        </li>
        <li>
          Personal Information. The personal information submitted by you in
          connection with your application to join the Developer Program and
          what you provide to us in connection with your participation in the
          Accelerator Program will be used for the purposes described in these
          Terms and will otherwise be handled in accordance with Ripple’s
          Privacy Policy, located at https://ripple.com/privacy-policy/, and you
          acknowledge and agree to such use.
        </li>
        <li>
          Assignment. You may not assign or transfer these Terms (by operation
          of law or otherwise) without the prior written consent of Ripple and
          any prohibited assignment will be null and void. Ripple may assign
          these Terms or any rights hereunder without your consent. Subject to
          the foregoing, these Terms will bind and inure to the benefit of the
          parties’ permitted successors and assigns.
        </li>
        <li>
          Miscellaneous. Ripple is not responsible for transmission errors
          resulting in omission, interruption, deletion, defect, delay in
          operations or transmission, theft or destruction or unauthorized
          access to or alterations of Participant Materials, or for technical,
          network, telephone equipment, electronic, computer, hardware or
          software malfunctions or limitations of any kind, or inaccurate
          transmissions of or failure to receive entry information by Ripple on
          account of technical problems or traffic congestion on the Internet or
          at any website or any combination thereof. Ripple is also not
          responsible for injury or damage to your computer or any other damage
          resulting from downloading any materials in connection with the Grants
          Program. In the event that any provision in these Terms is held to be
          invalid or unenforceable, the unenforceable part will be given effect
          to the greatest extent permitted by law and the remaining provisions
          will remain in full force and effect to the maximum extent permitted
          by law. The failure of a party to enforce any right or provision of
          these Terms will not be deemed a waiver of such right or provision.
          You agree that these Terms and the rules, restrictions and policies
          contained herein, and Ripple’s enforcement thereof, are not intended
          to confer and do not confer any rights or remedies upon any person
          other than you and Ripple. These Terms, any exhibits, and any other
          policies of Ripple incorporated herein by reference constitute the
          entire agreement between Ripple and you with respect to the subject
          matter of these Terms.
        </li>
        <li>
          APPLICABLE LAW AND VENUE. These Terms and your participation in the
          Developer Program will be governed by and construed in accordance with
          the laws of California, without resort to its conflict of law
          provisions. You agree that any action at law or in equity arising out
          of or relating to these Terms not subject to arbitration (as set forth
          below), will be filed only in the state and federal courts located in
          San Francisco County, California and you hereby irrevocably and
          unconditionally consent and submit to the exclusive jurisdiction of
          such courts over any suit, action or proceeding arising out of these
          Terms.
        </li>
        <li>
          LEGAL RELATIONSHIP: Nothing contained in this Terms or any other
          related documents, agreements, or conditions shall create any
          partnership, joint venture, employment or agency between you and
          Ripple for any purpose. You will not hold yourself out as having
          authority to bind Ripple in any way, and you will not make any
          agreements or representations on Ripple’s behalf.
        </li>
        <li>
          ARBITRATION. PLEASE READ THE FOLLOWING PARAGRAPH CAREFULLY BECAUSE IT
          REQUIRES YOU TO ARBITRATE DISPUTES WITH RIPPLE AND IT LIMITS THE
          MANNER IN WHICH YOU CAN SEEK RELIEF.
          <p>
            You and Ripple agree to arbitrate any dispute arising from these
            Terms or relating to the Site, except that you and Ripple are not
            required to arbitrate any dispute in which either party seeks
            equitable or other relief for the alleged unlawful use of
            copyrights, trademarks, trade names, logos, trade secrets or
            patents. ARBITRATION PREVENTS YOU FROM SUING IN COURT OR FROM HAVING
            A JURY TRIAL. You and Ripple agree that you will notify each other
            of any dispute within thirty (30) days of when it arises, that you
            will attempt informal resolution prior to any demand for
            arbitration, that any arbitration will occur in San Francisco,
            California and that arbitration will be conducted confidentially by
            a single arbitrator in accordance with the rules of JAMS. You and
            Ripple also agree that the state or federal courts in San Francisco
            County, California have exclusive jurisdiction over any appeals of
            an arbitration award and over any suit between the parties not
            subject to arbitration. In any arbitration, the parties will not
            seek discovery from each other, and the arbitrator shall not allow
            parties to engage in discovery; rather, each party shall disclose
            the evidence supporting their positions at some mutually agreeable
            time and date prior to the final hearing. Other than class
            procedures and remedies discussed below, the arbitrator has the
            authority to grant any remedy that would otherwise be available in
            court. Neither you nor we will participate in a class action or
            class-wide arbitration for any claims covered by these Terms. You
            also agree not to participate in claims brought in an private
            attorney general or representative capacity, or consolidated claims
            involving another person, if Ripple is a party to the proceeding. If
            the prohibition against class actions and other claims brought on
            behalf of third parties contained above is found to be
            unenforceable, then that language shall be deemed to have been
            dropped from the Terms and the remaining obligations relating to
            arbitration shall continue in full force and effect.
          </p>
        </li>
      </ol>

      <p>
        IF YOU DO NOT AGREE TO THESE TERMS, PLEASE DO NOT REGISTER OR OTHERWISE
        PARTICIPATE IN THE DEVELOPER PROGRAM.
      </p>
    </div>
  </Page>
)

export default Terms
